import { useGlobalConfig } from '@otovo/shared/hooks/configContext';
import useMobileVersion from '@otovo/shared/lib/useMobileVersion';
import { useEffect, useRef } from 'react';
import SectionWrapper from './SectionWrapper';

type Props = {
  heading: string;
  description: string;
};

const TrustBox = ({ heading, description }: Props) => {
  const config = useGlobalConfig();
  const ref = useRef(null);
  const { locale } = config.BU_CONFIG;
  const { TRUSTPILOT_BUSINESS_ID } = config;

  const shouldShowMobileWidget = useMobileVersion(800);

  useEffect(() => {
    if (window.Trustpilot) {
      window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  if (!TRUSTPILOT_BUSINESS_ID) {
    return null;
  }

  const sharedProps = {
    ref,
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    position: 'relative',

    'data-locale': locale,
    'data-businessunit-id': TRUSTPILOT_BUSINESS_ID,
    'data-style-width': '100%',
    'data-style-padding': '12px',
    'data-theme': 'light',
    'data-stars': '3,4,5',
  };

  return (
    <SectionWrapper>
      <div className="col-span-4">
        <h2 className="text-2xl font-medium">{heading}</h2>
        <p className="pt-3 text-base">{description}</p>
      </div>
      <div className="col-span-8 flex w-full items-center justify-center">
        {shouldShowMobileWidget ? (
          <div
            className="trustpilot-widget w-full rounded-lg p-2 shadow-lg"
            data-style-height="400px"
            data-template-id="539ad60defb9600b94d7df2c"
            data-tags="SelectedReview"
            {...sharedProps}
          />
        ) : (
          <div
            className="trustpilot-widget w-full rounded-lg p-5 shadow-lg"
            data-template-id="53aa8912dec7e10d38f59f36"
            data-tags="SelectedReview"
            {...sharedProps}
          />
        )}
      </div>
    </SectionWrapper>
  );
};
export default TrustBox;
